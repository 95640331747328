import React from "react";
import News1 from "./news/News1";
import News2 from "./news/News2";
import News3 from "./news/News3";
import News4 from "./news/News4";
import News5 from "./news/News5";

const BlogDetail = ({ id }) => {
  switch (id) {
    case "1":
      return <News1 />;
    case "2":
      return <News2 />;
    case "3":
      return <News3 />;
    case "4":
      return <News4 />;
    case "5":
      return <News5 />;
    default:
      return <>No Content</>;
  }
};

const BlogSingle = ({ id }) => {
  return (
    <>
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{
            backgroundImage:
              "url(" + require("../../images/banner/bnr4.jpg") + ")",
            height: 70,
          }}
        ></div>
        <div className="content-area">
          <div className="container max-w900">
            <div className="blog-post blog-single">
              <BlogDetail id={id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogSingle;
