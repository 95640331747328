import React, { useState } from "react";
import { Link } from "react-router-dom";
// Sliders
import "../../css/plugins.css";
import "../../css/skin/skin-1.css";
import "../../css/style.css";
import "../../css/templete.min.css";
import { useLangContext } from "../../lang-context";
import Counter from "../element/counter";
import HomeSlider from "../element/homeslider1";
// import emailjs from "emailjs-com";

const backgroundImage = require("../../images/background/bg17.jpg");

const Index3 = ({}) => {
  const { lang } = useLangContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  // const sendEmail = () => {
  //   const templateParams = {
  //     dzName: name,
  //     dzEmail: email,
  //     dzMessage: message,
  //   };

  //   emailjs
  //     .send(
  //       "YOUR_SERVICE_ID",
  //       "YOUR_TEMPLATE_ID",
  //       templateParams,
  //       "YOUR_USER_ID"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         setEmailSent(true);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };

  return (
    <>
      <HomeSlider contentWrapperCls="sliderStyle3" hideBtn />

      <div className="page-content bg-white">
        <div className="content-block">
          <div id="news" className="section-full content-inner bg-gray">
            <div className="container">
              <div class="section-head text-center">
                <h2 class="title">
                  {lang === "mn" ? "Мэдээ Мэдээлэл" : "News"}
                </h2>
              </div>

              <div className="row">
                <div
                  className="col-lg-3 col-md-6 wow bounceInRight"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="blog-post blog-grid blog-rounded blog-effect1">
                    <div className="dlab-post-media dlab-img-effect zoom">
                      <Link to="/blog-5">
                        <img
                          src={require("../../images/new/meeting.jpg")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="dlab-info p-a20 border-1 bg-white">
                      <div className="dlab-post-meta">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <strong>25 MAR</strong> <span> 2025</span>{" "}
                          </li>
                          <li className="post-author">
                            {" "}
                            By <Link to="#">Uuganbold</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dlab-post-title">
                        <h4 className="post-title">
                          <Link to="/blog-5">Хурлын зар 2025</Link>
                        </h4>
                      </div>
                      <div className="dlab-post-text">
                        <p>
                          {lang === "mn"
                            ? "“ЭМ ЭН ДИ” ХК-ИЙН ХУВЬЦАА ЭЗЭМШИГЧДИЙН ХУРЛЫН ЗАР"
                            : "The share price of 'MND' (DLH+14.42%) rose the highest."}
                        </p>
                      </div>
                      <div className="dlab-post-readmore">
                        <Link
                          to="/blog-5"
                          title="READ MORE"
                          rel="bookmark"
                          className="site-button btnhover15"
                        >
                          {lang === "mn" ? "ДЭЛГЭРЭНГҮЙ" : "READ MORE"}
                          <i className="ti-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow bounceInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="blog-post blog-grid blog-rounded blog-effect1">
                    <div className="dlab-post-media dlab-img-effect zoom">
                      <Link to="/blog-1">
                        <img
                          src={require("../../images/new/news1.png")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="dlab-info p-a20 border-1 bg-white">
                      <div className="dlab-post-meta">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <strong>09 Nov</strong> <span> 2017</span>{" "}
                          </li>
                          <li className="post-author">
                            {" "}
                            By <Link>Uuganbold</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dlab-post-title">
                        <h5 className="post-title">
                          <Link to="/blog-1">“ЭМ ЭН ДИ” ХК</Link>
                        </h5>
                      </div>
                      <div className="dlab-post-text">
                        <p>
                          “СЭЛЭНГЭ ДУЛААНХАН” ХК-ийн оноосон нэрийг “ЭМ ЭН ДИ”
                          ХК болгон өөрчиллөө.
                        </p>
                      </div>
                      <div className="dlab-post-readmore">
                        <Link
                          to="/blog-1"
                          title="READ MORE"
                          rel="bookmark"
                          className="site-button btnhover15"
                        >
                          {lang === "mn" ? "ДЭЛГЭРЭНГҮЙ" : "READ MORE"}
                          <i className="ti-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="blog-post blog-grid blog-rounded blog-effect1">
                    <div className="dlab-post-media dlab-img-effect zoom">
                      <Link to="/blog-2">
                        <img
                          src={require("../../images/new/news5.png")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="dlab-info p-a20 border-1 bg-white">
                      <div className="dlab-post-meta ">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <strong>11 Sep</strong> <span> 2017</span>{" "}
                          </li>
                          <li className="post-author">
                            {" "}
                            By <Link to="#">Uuganbold</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dlab-post-title">
                        <h4 className="post-title">
                          <Link to="/blog-2">Тендер санал</Link>
                        </h4>
                      </div>
                      <div className="dlab-post-text">
                        <p>
                          "СЭЛЭНГЭ ДУЛААНХАН” ХК-ийн хувьцааг худалдан авах
                          тендер санал.
                        </p>
                      </div>
                      <div className="dlab-post-readmore">
                        <Link
                          to="/blog-2"
                          title="READ MORE"
                          rel="bookmark"
                          className="site-button btnhover15"
                        >
                          {lang === "mn" ? "ДЭЛГЭРЭНГҮЙ" : "READ MORE"}
                          <i className="ti-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 wow bounceInRight"
                  data-wow-duration="2s"
                  data-wow-delay="0.3s"
                >
                  <div className="blog-post blog-grid blog-rounded blog-effect1">
                    <div className="dlab-post-media dlab-img-effect zoom">
                      <Link to="/blog-3">
                        <img
                          src={require("../../images/new/news4.png")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="dlab-info p-a20 border-1 bg-white">
                      <div className="dlab-post-meta">
                        <ul>
                          <li className="post-date">
                            {" "}
                            <strong>15 SEP</strong> <span> 2017</span>{" "}
                          </li>
                          <li className="post-author">
                            {" "}
                            By <Link to="#">Uuganbold</Link>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="dlab-post-title">
                        <h4 className="post-title">
                          <Link to="/blog-3">"Эм Эн Ди"-ын ханш</Link>
                        </h4>
                      </div>
                      <div className="dlab-post-text">
                        <p>
                          {lang === "mn"
                            ? "'Эм Эн Ди' (DLH+14.42%)-ийн хувьцааны ханш хамгийн өндөр өсөв."
                            : "The share price of 'MND' (DLH+14.42%) rose the highest."}
                        </p>
                      </div>
                      <div className="dlab-post-readmore">
                        <Link
                          to="/blog-3"
                          title="READ MORE"
                          rel="bookmark"
                          className="site-button btnhover15"
                        >
                          {lang === "mn" ? "ДЭЛГЭРЭНГҮЙ" : "READ MORE"}
                          <i className="ti-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-full content-inner overlay-black-dark bg-img-fix"
          style={{ backgroundImage: "url(" + backgroundImage + ")" }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInLeft"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 text-center text-white">
                  <div className="">
                    <Counter count={97360} />
                  </div>
                  <span className="counter-text">Нийт гаргасан хувьцаа</span>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInDown"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 text-center text-white">
                  <div className="">
                    <Counter count={1073} />
                  </div>
                  <span className="counter-text">
                    Нэгж хувьцааны дансны үнэ
                  </span>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 text-center text-white">
                  <div className="">
                    <Counter count={97360} />
                  </div>
                  <span className="counter-text">Нийт гаргасан хувьцаа</span>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30 wow fadeInRight"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div className="counter-style-1 text-center text-white">
                  <div className="">
                    <Counter count={1073} />
                  </div>
                  <span className="counter-text">
                    Нэгж хувьцааны дансны үнэ
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-block">
          <div className="section-full text-center bg-white content-inner">
            <div className="container">
              <div class="section-head text-center">
                <h2 class="title">
                  {lang === "mn" ? "Удирдлагын баг" : "Management team"}
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="dlab-box m-b30 dlab-team1"
                    style={{ marginBottom: 40 }}
                  >
                    <div className="dlab-media">
                      <Link to="#">
                        <img
                          width="358"
                          height="460"
                          alt=""
                          src={require("../../images/new/no-profile.png")}
                        />
                      </Link>
                    </div>
                    <div className="dlab-info">
                      <span className="dlab-position" style={{ fontSize: 10 }}>
                        Батсүх Эрдэнэтуяа
                        <br />
                        ТУЗ-ийн Нарийн бичгийн дарга
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="dlab-box m-b30 dlab-team1"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="dlab-media">
                      <Link to="#">
                        <img
                          width="358"
                          height="460"
                          alt=""
                          src={require("../../images/new/no-profile.png")}
                        />
                      </Link>
                    </div>
                    <div className="dlab-info">
                      <span className="dlab-position" style={{ fontSize: 10 }}>
                        Н. Ганчимэг <br /> ТУЗ-ийн дарга
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="dlab-box m-b30 dlab-team1"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="dlab-media">
                      <Link to="#">
                        <img
                          width="358"
                          height="460"
                          alt=""
                          src={require("../../images/new/no-profile.png")}
                        />
                      </Link>
                    </div>
                    <div className="dlab-info">
                      <span className="dlab-position" style={{ fontSize: 10 }}>
                        Намсрай Отгон-Од
                        <br /> Гүйцэтгэх захирал
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className="dlab-box m-b30 dlab-team1"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="dlab-media">
                      <Link to="#">
                        <img
                          width="358"
                          height="460"
                          alt=""
                          src={require("../../images/new/no-profile.png")}
                        />
                      </Link>
                    </div>
                    <div className="dlab-info">
                      <span className="dlab-position" style={{ fontSize: 10 }}>
                        Намсрай Отгон-Од
                        <br /> Гүйцэтгэх захирал
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="contact_us"
          className="section-full content-inner bg-gray contact-style-1"
        >
          <div className="container">
            <div class="section-head text-center">
              <h2 class="title">
                {lang === "mn" ? "Холбоо барих" : "Contact us"}
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex m-b30">
                <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                  <ul className="no-margin">
                    <li className="icon-bx-wraper left m-b30">
                      <div className="icon-bx-xs border-1">
                        {" "}
                        <Link to="/#" className="icon-cell">
                          <i className="ti-location-pin"></i>
                        </Link>{" "}
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dlab-tilte">
                          {lang === "mn" ? "Хаяг:" : "Address:"}
                        </h6>
                        <p>
                          {lang === "mn"
                            ? "УБ ХУД 15-р хороо, Махатма гандигийн гудамж, Жаргалан хотхон АОС-24"
                            : "15th khoroo, Mahatma Gandhi Avenue, Jargalan Hotkhon, Ulaanbaatar, Mongolia, AOS-24"}
                        </p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper left  m-b30">
                      <div className="icon-bx-xs border-1">
                        {" "}
                        <Link to="/#" className="icon-cell">
                          <i className="ti-email"></i>
                        </Link>{" "}
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dlab-tilte">
                          {lang === "mn" ? "Имэйл:" : "E-mail:"}
                        </h6>
                        <p className="m-b0">MNDJSC99@gmail.com</p>
                      </div>
                    </li>
                    <li className="icon-bx-wraper left">
                      <div className="icon-bx-xs border-1">
                        {" "}
                        <Link to="/#" className="icon-cell">
                          <i className="ti-mobile"></i>
                        </Link>{" "}
                      </div>
                      <div className="icon-content">
                        <h6 className="text-uppercase m-tb0 dlab-tilte">
                          {lang === "mn" ? "Утас:" : "Phone Numbers:"}
                        </h6>
                        <p className="m-b0">(+976) 9605-1551</p>
                      </div>
                    </li>
                  </ul>
                  {/* <div className="m-t20">
                  <ul className="dlab-social-icon border dlab-social-icon-lg">
                    <li>
                      <Link
                        to="/#"
                        className="fa fa-facebook bg-primary"
                      ></Link>
                    </li>
                    <li>
                      <Link to="/#" className="fa fa-twitter bg-primary"></Link>
                    </li>
                    <li>
                      <Link
                        to="/#"
                        className="fa fa-linkedin bg-primary"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        to="/#"
                        className="fa fa-pinterest-p bg-primary"
                      ></Link>
                    </li>
                    <li>
                      <Link
                        to="/#"
                        className="fa fa-google-plus bg-primary"
                      ></Link>
                    </li>
                  </ul>
                </div> */}
                </div>
              </div>

              <div className="col-lg-4 col-md-6  mb-4 m-b30 mb-md-0">
                <div
                  className="p-a30 bg-gray clearfix radius-sm"
                  style={{ paddingTop: 5 }}
                >
                  <h3 className="m-b10">
                    {lang === "mn" ? "Санал хүсэлт" : "Inquiry"}
                  </h3>
                  {/* <p>
                  Бид та бүхний санал хүсэлтийг доорх аргуудаар хүлээн авч үйл
                  ажиллагаагаа тогтмол сайжруулан ажиллах болно. Баярлалаа.
                </p> */}
                  <div className="dzFormMsg"></div>
                  <form className="dzForm">
                    <input type="hidden" value="Contact" name="dzToDo" />
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="dzName"
                              type="text"
                              required
                              className="form-control"
                              placeholder={
                                lang === "mn" ? "Таны нэр" : "Your Name"
                              }
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="dzEmail"
                              type="email"
                              className="form-control"
                              required
                              placeholder={
                                lang === "mn"
                                  ? "Таны И-мэйл хаяг"
                                  : "Your Email Id"
                              }
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <textarea
                              name="dzMessage"
                              rows="4"
                              className="form-control"
                              required
                              placeholder={
                                lang === "mn" ? "Санал хүсэлт" : "Your message"
                              }
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          name="submit"
                          type="button"
                          value="Submit"
                          className="site-button"
                          onClick={() => {}}
                        >
                          {" "}
                          <span>
                            {lang === "mn" ? "Илгээх" : "Submit"}
                          </span>{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-4 d-flex m-b30">
                <iframe
                  title="map2"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1353.655307642673!2d106.9206351!3d47.9052151!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5e5b7586e76e7c57%3A0x225573e9876fc9ac!2z0JrQsNGA0LrQvtC10YDQvdC40LkgMTUtyB6dG9nZiwgTWFoYXRtYSBnYW5kaWdpdGkbnSDQodGC0L3Ri9C5INGA0YHQutCw0Y8gMjQ2NDAx!5e0!3m2!1sen!2sus!4v1647342671292!5m2!1sen!2sus&q=%D0%A3%D0%91%20%D0%A5%D0%A3%D0%94%2015-%D1%80%20%D1%85%D0%BE%D1%80%D0%BE%D0%BE,%20%D0%9C%D0%B0%D1%85%D0%B0%D1%82%D0%BC%D0%B0%20%D0%B3%D0%B0%D0%BD%D0%B4%D0%B8%D0%B3%D0%B8%D0%B9%D0%BD%20%D0%B3%D1%83%D0%B4%D0%B0%D0%BC%D0%B6,%20%D0%96%D0%B0%D1%80%D0%B3%D0%B0%D0%BB%D0%B0%D0%BD%20%D1%85%D0%BE%D1%82%D1%85%D0%BE%D0%BD%20%D0%90%D0%9E%D0%A1-24"
                  className="align-self-stretch radius-sm"
                  style={{
                    border: "0",
                    width: "100%",
                    minHeight: "100%",
                  }}
                  allowfullscreen
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index3;
