import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useLangContext } from "../../lang-context";
import "./ActivityReport.scss";
import { Document, Page, pdfjs } from 'react-pdf';
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const pdfURL = "/2025.pdf";

function ActivityReport() {
  const numPages = 8;
  const { lang } = useLangContext();
  const [pageNumber, setPageNumber] = useState(1);

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container text-center">
            <div
              className="content-bx1 m-b40"
              style={{
                paddingLeft: 0,
              }}
            >
              <h3
                className="m-b15 title"
                style={{ textTransform: "uppercase" }}
              >
                {lang === "mn" ? "Үйл ажиллагааны тайлан 2024, 2023" : "Activity Report for 2024, 2023"}
              </h3>
            </div>
            <Document file={pdfURL}>
              <Page pageNumber={pageNumber} />
            </Document>
            <ul className="pagination">
              <li>
                <button onClick={goToPreviousPage} disabled={pageNumber === 1}>
                  {lang === "mn" ? "Өмнөх хуудас" : "Previous"}
                </button>
              </li>
              <li>
                {lang === "mn"
                  ? `${numPages} хуудасны ${pageNumber}-р хуудас`
                  : `Page ${pageNumber} of ${numPages}`}
              </li>
              <li>
                <button
                  onClick={goToNextPage}
                  disabled={pageNumber === numPages}
                >
                  {lang === "mn" ? "Дараагийн хуудас" : "Next"}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityReport;
