import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../../lang-context";

const News4 = () => {
  const { lang } = useLangContext();

  return (
    <>
      {" "}
      <div className="dlab-post-meta">
        <ul>
          <li className="post-date">
            {" "}
            <strong>25 Mar</strong> <span> 2025</span>{" "}
          </li>
          <li className="post-author">
            {" "}
            By <Link to="/#">Uuganbold</Link>{" "}
          </li>
        </ul>
      </div>
      <div className="dlab-post-title ">
        <h4 className="post-title m-t0">
          <Link to="/blog-single-sidebar">
            {lang === "mn"
              ? "“ЭМ ЭН ДИ” ХК-ИЙН ХУВЬЦАА ЭЗЭМШИГЧДИЙН ХУРЛЫН ЗАР 2025"
              : "“ЭМ ЭН ДИ” ХК-ИЙН ХУВЬЦАА ЭЗЭМШИГЧДИЙН ХУРЛЫН ЗАР 2025"}
          </Link>
        </h4>
      </div>
      <div>
        <p>
          “Эм Эн Ди” ХК-ийн хувьцаа эзэмшигчдийн ээлжит хурал 2025 оны 04-р
          сарын 25-ны өдрийн 11:00 цагт Хан-Уул дүүрэг 15-р хороо, Стадион оргил
          /17010/ Чингисийн өргөн чөлөө гудамж Кей би тауэр 2 давхарт 203 тоотын
          хурлын танхимд болох тул Эрхэм хүндэт хувьцаа эзэмшигчид та бүхнийг
          хүрэлцэн ирэхийг урьж байна.
        </p>
        <p style={{ fontWeight: 500 }}>
          Хуралд оролцох эрхтэй хувьцаа эзэмшигчдийг бүртгэх өдөр: 2025 оны 04-р
          сарын 07-ны өдөр
        </p>
        <p style={{ fontWeight: 500 }}>
          Хурлаар хэлэлцэх асуудлууд:
          <ul style={{ paddingLeft: "1.5rem" }}>
            <li>
              Компанийн 2024 оны үйл ажиллагааны болон санхүүгийн тайланд өгөх
              Төлөөлөн удирдах зөвлөлийн гаргасан дүгнэлтийг хэлэлцэж батлах
            </li>
            <li>
              Компанийн дүрэмд нэмэлт, өөрчлөлт оруулах тухай асуудлыг хэлэлцэн
              батлах
            </li>
            <li>
              Компанийн ноогдол ашиг тараахгүй байх ТУЗ-ийн гаргасан тогтоолын
              танилцуулга
            </li>
            <li>
              ТУЗ-ийн бүрэн эрхийг бүрэн бүрдэлдэхүүнээр нь дуусгавар болгож,
              ТУЗ-ийн гишүүдийг кумулятив аргаар сонгох тухай
            </li>
            <li>ТУЗ-ийн гишүүдийн цалин, урамшууллын хэмжээг тогтоох тухай</li>
          </ul>
        </p>
        <p>
          Эрхэм хувьцаа эзэмшигч та бүхэн хурлын материалтай өөрийн харьяалагдах
          брокер, диллерийн компани болон Хан-Уул дүүрэг 15-р хороо, Стадион
          оргил /17010/ Чингисийн өргөн чөлөө гудамж Кэй би тауэр 2 давхарт 203
          тоотод хүрэлцэн ирж танилцана уу.
        </p>
        <p style={{ color: "#666" }}>
          <span style={{ fontWeight: 600 }}>Холбоо барих:</span>
          <br />
          Холбоо барих утас: 77127712
          <br />
          Имэйл хаяг: MNDJSC99@gmail.com
        </p>
      </div>
    </>
  );
};

export default News4;
