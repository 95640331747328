import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../../lang-context";

const newsImage3 = require("../../../images/new/meeting_note.png");
const News4 = () => {
  const { lang } = useLangContext();

  return (
    <>
      {" "}
      <div className="dlab-post-meta">
        <ul>
          <li className="post-date">
            {" "}
            <strong>20 Apr</strong> <span> 2023</span>{" "}
          </li>
          <li className="post-author">
            {" "}
            By <Link to="/#">Uuganbold</Link>{" "}
          </li>
        </ul>
      </div>
      <div className="dlab-post-title ">
        <h4 className="post-title m-t0">
          <Link to="/blog-single-sidebar">
            {lang === "mn"
              ? "“ЭМ ЭН ДИ” ХК-ИЙН ХУВЬЦАА ЭЗЭМШИГЧДИЙН ХУРЛЫН ЗАР 2023"
              : "“ЭМ ЭН ДИ” ХК-ИЙН ХУВЬЦАА ЭЗЭМШИГЧДИЙН ХУРЛЫН ЗАР 2023"}
          </Link>
        </h4>
      </div>
      <div>
        <p>
          “Эм Эн Ди” ХК-ийн хувьцаа эзэмшигчдийн ээлжит хурал 2023 оны 04-р
          сарын 26-ны өдрийн 11:00 цагт Жаргалан хотхон АОС 24 хурлын танхимд
          болох тул Эрхэм хүндэт хувьцаа эзэмшигчид та бүхнийг хүрэлцэн ирэхийг
          урьж байна.
        </p>
        <p style={{ fontWeight: "500" }}>
          Хуралд оролцох эрхтэй хувьцаа эзэмшигчдийг бүртгэх өдөр: 2023 оны 04-р сарын 06-ны өдөр
        </p>
        <p style={{ fontWeight: "500" }}>
          Хурлаар хэлэлцэх асуудлууд:
          <ul style={{ paddingLeft: "1.5rem" }}>
            <li>
              Компанийн 2022 оны бизнесийн үйл ажиллагааны болон санхүүгийн
              тайлан, түүнд хийгдсэн хараат бус аудитын байгууллагын тайланг
              танилцуулах
            </li>
            <li>
              Компанийн ноогдол ашиг тараахгүй байх ТУЗ-ийн гаргасан тогтоолын
              танилцуулга
            </li>
            <li>Компанийн 2023 оны үйл ажиллагааны зорилтын тухай</li>
            <li>Компанийн ТУЗ-ийн гишүүдийн бүрэн эрхийг сунгах тухай</li>
          </ul>
        </p>
        <p>
          Эрхэм хувьцаа эзэмшигч та бүхэн хурлын материалтай өөрийн харьяалагдах
          брокер, диллерийн компани болон Улаанбаатар хот, Хан уул дүүргийн 15-р
          хороо, Жаргалан хотхон хаус 24-р хауст хүрэлцэн ирж танилцана уу.
        </p>
        <p style={{ color: "#666" }}>
          <span style={{ fontWeight: "600" }}>Холбоо барих:</span>
          <br />
          Холбоо барих утас: 77127712, 96051551
          <br />
          Имэйл хаяг: MNDJSC99@gmail.com
        </p>
      </div>
    </>
  );
};

export default News4;
