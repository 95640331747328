import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../Layout";
import Index3 from "./pages";
import ActivityReport from "./pages/activityReport";
import BlogSingle from "./pages/blogSingle";
import About1 from "./pages/ceoGreeting";
import CompanyDetail from "./pages/company";
import CompanyHistory from "./pages/companyHistory";
import Directors from "./pages/directors";
import Error404 from "./pages/error-404";
import FinancialReport from "./pages/financial";
import About3 from "./pages/greeting";
import Kebat from "./pages/kebat";
import Organizational from "./pages/Organizational";
import Shareholders from "./pages/shareholders";
import StockPrice from "./pages/stockPrice";
import TenderDetail from "./pages/tender";

const Router = () => {
  return (
    <BrowserRouter basename={"/"}>
      <div className="page-wraper">
        <Layout>
          <Switch>
            <Route path="/" exact component={Index3} />
            <Route path="/greeting" component={About1} />
            <Route path="/greeting-ceo" component={About3} />
            <Route path="/about_us" component={CompanyDetail} />
            <Route path="/history" component={CompanyHistory} />
            <Route path="/tender" component={TenderDetail} />
            <Route path="/financial_report" component={FinancialReport} />
            <Route path="/company_organizational" component={Organizational} />
            <Route path="/stock_price" component={StockPrice} />
            <Route path="/shareholders" component={Shareholders} />
            <Route path="/kebat" component={Kebat} />
            <Route path="/activity_report" component={ActivityReport} />
            <Route path="/directors" component={Directors} />
            <Route path="/blog-1" component={() => <BlogSingle id={"1"} />} />
            <Route path="/blog-2" component={() => <BlogSingle id={"2"} />} />
            <Route path="/blog-3" component={() => <BlogSingle id={"3"} />} />
            <Route path="/blog-4" component={() => <BlogSingle id={"4"} />} />
            <Route path="/blog-5" component={() => <BlogSingle id={"5"} />} />
            <Route component={Error404} />
          </Switch>
        </Layout>
      </div>
    </BrowserRouter>
  );
};
export default Router;
